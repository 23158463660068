<script setup lang="ts">
import LayoutHeader from "@/components/layout/LayoutHeader.vue";
import LayoutFooter from "@/components/layout/LayoutFooter.vue";
import BenefitBar from "@/components/atoms/BenefitBar";
import { useBenefitBarDetailsStore } from "@/stores/benefitBarDetails";
defineComponent({
  name: "DefaultLayout",
});

const { texts, shouldBenefitBarBeDisplayed } = toRefs(
  useBenefitBarDetailsStore(),
);

// Navigation for default theme (depth 1 is 2 level navi, depth 2 is 3 level navi, and so on)
const { loadNavigationElements } = useNavigation();
const { data } = useAsyncData("mainNavigation", () => {
  return loadNavigationElements({ depth: 1 });
});
provide("swNavigation-main-navigation", data);

const { loadNavigationElements: loadFooterNavigationElements } = useNavigation({
  type: "footer-navigation",
});
const { data: footerData } = useAsyncData("mainFooterNavigation", () => {
  return loadFooterNavigationElements({ depth: 1 });
});
provide("swNavigation-footer-navigation", footerData);
</script>
<template>
  <div class="w-full">
    <div class="w-full min-h-dvh">
      <BenefitBar
        v-if="shouldBenefitBarBeDisplayed"
        :texts="texts"
        class="benefit-bar--on-top-layout"
      />
      <div class="max-w-screen-3xl mx-auto flex flex-col w-full">
        <LayoutHeader />
        <main class="bg-white grow">
          <slot />
        </main>
        <LayoutFooter />
      </div>
    </div>
  </div>
</template>
